<template>
  <div class="batchBox">
    <el-row
      class="accountCon"
    >
      <el-col class="leftCon" :span="4">
        <div class="label1">
          <i class="el-icon-collection-tag" /> 商品信息
          <img src="@/assets/images/triangle.png" alt="">
        </div>
      </el-col>
      <el-col class="rightCon" :span="24">
        <el-form
          ref="detail"
          label-position="right"
          label-width="170px"
          :rules="rules"
          :model="detail"
          class="formBox"
        >

          <el-form-item label="商品名称:" prop="name">
            <el-input
              v-model="detail.name"
              class="w300"
              maxlength="150"
              placeholder="请输入商品名称"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="商品分类:" prop="category_id">
            <el-select
              v-model="detail.category_id"
              class="w300"
              clearable
              placeholder="请选择商品分类"
            >
              <el-option
                v-for="item in options.category_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="商品规格:" >
            <el-input
              v-model="detail.spec_name"
              class="w300"
              maxlength="150"
              show-word-limit
              placeholder="请输入商品规格"
            />
          </el-form-item>
          <el-form-item label="金蝶编码:" prop="kingdee_code">
            <el-input
              v-model="detail.kingdee_code"
              class="w300"
              maxlength="150"
              show-word-limit
              placeholder="请输入金蝶编码"
            />
          </el-form-item>
          <el-form-item label="商品金蝶销售单位编码:" prop="unit_code">
            <el-input
              v-model="detail.unit_code"
              class="w300"
              maxlength="150"
              show-word-limit
              placeholder="请输入金蝶销售单位编码"
            />
          </el-form-item>
          <el-form-item label="商品单位名称:" prop="unit_name">
            <el-input
              v-model="detail.unit_name"
              class="w300"
              maxlength="150"
              show-word-limit
              placeholder="请输入单位名称"
            />
          </el-form-item>
          <el-form-item label="商品销售价格:" prop="goods_price">
            <el-input
              v-model="detail.goods_price"
              class="w300"
              clearable
              placeholder="请输入销售价格"
            >
            <span slot="append">元</span>
            </el-input>
          </el-form-item>
          <el-form-item label="商品货号:">
            <el-input
              v-model="detail.goods_sn"
              class="w300"
              maxlength="150"
              show-word-limit
              placeholder="请输入商品货号"
            />
          </el-form-item>

          <el-form-item label="类型:">
            <el-radio-group v-model="detail.is_project">
              <el-radio :label="1">商品</el-radio>
              <el-radio :label="2">服务</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="商品归属:" prop="shop_type">
            <el-select
              v-model="detail.shop_type"
              class="w300"
              clearable
              placeholder="请选择门店分类"
            >
              <el-option
                v-for="item in options.shop_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="是否上架:">
            <el-radio-group v-model="detail.status">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="商品备注:">
            <el-input
              v-model="detail.desc"
              class="w300"
              placeholder="请填写备注"
              type="textarea"
            />
          </el-form-item>
          <div class="btnBox">
          <el-button
            type="primary"
            size="mini"
            class="mainBtn"
            @click="submitForm('detail')"
          >完成，提交商品</el-button>
          </div>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { createGoods,goodsGetail,updateGoods,categoryList } from '@/api/goods';
class Detail {
    name='';
    category_id='';
    spec_name='';
    kingdee_code='';
    unit_code='';
    unit_name='';
    goods_price='';
    goods_sn='';
    desc='';
    is_project = 1;
    status=1;
    is_project=1;
    shop_type = 0;
}
class Rules {
  name = [
    { required: true, message: '请填写商品名称', trigger: 'blur' }
  ];
  category_id = [
    { required: true, message: '请选择商品分类', trigger: 'change' }
  ];
  kingdee_code = [
    { required: true, message: '请填写金蝶编码', trigger: 'blur' }
  ];
  unit_code = [
    { required: true, message: '请填写商品金蝶销售单位编码', trigger: 'blur' }
  ];
  unit_name = [
    { required: true, message: '请填写商品单位名称', trigger: 'blur' }
  ];
  goods_price = [
    { required: true, message: '请填写商品销售价格', trigger: 'blur' }
  ];
  goods_sn = [
    { required: true, message: '请填写商品货号', trigger: 'blur' }
  ];

  shop_type = [
    { required: true, message: '请选择门店类型', trigger: 'blur' },
  ];
}
class Options {
 category_id = [];
 shop_type = [
    {id: 0,name: "全部"},
    {id: 1,name: "门店"},
    {id: 2,name: "门诊"}
 ];
}
export default {
  props: {

  },

  data () {
    return {
      options: new Options(), // 选项
      detail: new Detail(),
      rules: new Rules(),
    }
  },

  mounted () {
    if(this.$route.query.id )this.getDetail()
    this.getOptions()
  },
  methods: {
    // 获取选项
    getOptions(){
       categoryList({page:1,limit:10000}).then(res => {
        this.options.category_id = res.list;
      })
    },
    // 获取详情
    getDetail(){
       goodsGetail({id:this.$route.query.id} ).then(res => {
        this.detail = res;
      })
    },
    // 提交
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$route.query.id?this.hint('更新'):this.hint('新建')

        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 提示
    hint(text){
       this.$confirm(`确定${text}此商品吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.confirm()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
    },
    // 确认提交
     confirm() {
       if(this.$route.query.id ){
         updateGoods({...this.detail} ).then(res => {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.$router.push('./goodsList')
        })
       }else{
         createGoods({...this.detail} ).then(res => {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.$router.push('./goodsList')
        })
       }


    }
  }
}
</script>

<style lang="scss" scoped>
.accountCon{
    background: #fff;
    .leftCon{
      background: #fcfcfc;
      border-right: 1px solid #eee;
      position: absolute;
    height: 100%;
      >div{
        line-height: 48px;
        color: #fff;
        background: #9EA7B4;
        width: 100%;
        padding-left: 80px;
        font-size: 14px;
        box-sizing: border-box;
        img{
          position: absolute;
          right: -30px;
          width: 40px;
          height: 48px;
        }
      }
      .label1{
        position: absolute;
        top: 40px;
      }
      .label2{
        position: absolute;
        top: 270px;
      }
    }
    .rightCon{
      padding: 40px 0 0 260px;
      .btn{
        margin: 20px 0 50px  80px;
      }
      .btnBox{
      width: 100%;
      text-align: center;
    }
      .mainBtn{
        margin: 30px 0 0 0px;
      }
      .phoneTip{
        font-size: 12px;
        color: #999;
      }
      .formBox{
      display: flex;
      flex-wrap: wrap;
      .el-form-item{
        width: 50%;
      }
    }
    }
}
</style>
